'use client';
import type { ChangeEvent, JSX } from 'react';
import { Select } from 'geist/components';
import { useRouter } from '@pyra/compat-router';
import type { SideBarItem } from '@pyra/docs-shared/data/navigation/types';
import { Workflow } from '@pyra/docs-shared/data/navigation/documentation/workflow';
import styles from './select.module.css';

export function SelectRules(): JSX.Element {
  const conformanceNav = Workflow.posts.filter(
    (item) => item.name === 'Conformance',
  )[0] as unknown as SideBarItem;
  const rules = conformanceNav.posts?.filter(
    (item) => item.name === 'Rules',
  )[0] as unknown as SideBarItem;
  const router = useRouter();
  function onSelectChange(e: ChangeEvent<HTMLSelectElement>): void {
    router.push(e.target.value);
  }
  return (
    <div className={styles.wrapper}>
      <Select
        className={styles.select}
        label="Conformance Rules"
        onChange={onSelectChange}
      >
        <option selected={router.pathname === rules.href} value={rules.href}>
          View All Rules
        </option>
        {rules.posts && rules.posts.length > 0
          ? rules.posts.map((ruleItem) => (
              <option
                key={ruleItem.name}
                selected={router.pathname === ruleItem.href}
                value={ruleItem.href}
              >
                {ruleItem.name}
              </option>
            ))
          : null}
      </Select>
    </div>
  );
}
