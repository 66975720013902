import { type ReactNode, Children, isValidElement } from 'react';
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { countSyllables } from '#/app/components/layout/utils';

export const getInnerTextFromReactNode = (children: ReactNode): string => {
  let text = '';

  const childrenArray = Children.toArray(children);
  childrenArray.forEach((child) => {
    // Check if the child is an element and has the 'badge' class in a 'span' - This is sometimes used to render badges next to the page headings
    if (isValidElement(child)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (child.type === 'span' && child.props?.className?.includes('badge')) {
        return;
      }
      if (child.props.children) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        text += getInnerTextFromReactNode(child.props.children);
      }
    } else if (typeof child === 'string') {
      text += child;
    }
  });

  return text;
};

function getHref(children: ReactNode): string {
  let href = '';
  Children.forEach(children, (child) => {
    if (isValidElement(child)) {
      if (child.type === 'a' && child.props?.href) {
        href = child.props.href as string;
      }
    }
  });
  return href;
}

export function timeToRead(children: React.ReactNode): number {
  let content;
  try {
    content = getInnerTextFromReactNode(children);
  } catch (error) {
    content = '';
  }
  const wordsPerMinute = 240;
  const words = content.split(/\s+/g);
  const numberOfWords = words.length;
  const numberOfSyllables = words.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (acc, word) => acc + countSyllables(word),
    0,
  );
  const numberOfPauses = content.split(/[.,;:!?]\s/g).length - 1;

  // Adjust the reading speed based on the average syllables per word
  const adjustedWordsPerMinute =
    wordsPerMinute * (1 + (numberOfSyllables / numberOfWords - 1.5) * 0.1);

  // Add extra time for pauses (e.g., 0.2 seconds for each pause)
  const extraTimeForPauses = numberOfPauses * 0.2;
  const readingTime =
    (numberOfWords / adjustedWordsPerMinute) * 60 + extraTimeForPauses;

  return Math.ceil(readingTime / 60);
}

export function extractHeadings(
  child: ReactNode,
): { name: string; href: string; level: number }[] {
  let headings: { name: string; href: string; level: number }[] = [];
  let newChild;
  if (Array.isArray(child)) {
    newChild = [];
    child.forEach((item) => {
      if (item !== null && item !== ' ') {
        if (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          !(item.$$typeof && item.$$typeof.toString() === 'Symbol(react.lazy)')
        ) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          newChild.push(item);
        }
      }
    });
  } else {
    newChild = child;
  }
  Children.forEach(newChild as ReactNode, (node) => {
    if (isValidElement(node)) {
      if (
        node.type === 'h1' ||
        node.type === 'h2' ||
        node.type === 'h3' ||
        node.type === 'h4' ||
        node.type === 'h5' ||
        node.type === 'h6'
      ) {
        const level = parseInt(node.type.substring(1));
        const name = getInnerTextFromReactNode(
          node.props.children as ReactNode,
        );
        const href = getHref(node.props.children as ReactNode);

        headings.push({
          name,
          href,
          level,
        });
      }
      if (node.props.children && node.props.children) {
        headings = headings.concat(
          extractHeadings(node.props.children as ReactNode),
        );
      }
    }
  });

  return headings;
}
/* eslint-enable @typescript-eslint/no-unsafe-member-access */
