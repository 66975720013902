import { relatedContentItems } from './data';
import type { RelatedContentType, RelatedItem } from './types';

export function relatedPageItems(path: string): RelatedItem[] {
  const RelatedItems = relatedContentItems as RelatedContentType;
  if (Array.isArray(RelatedItems[path]) && RelatedItems[path].length > 0) {
    return RelatedItems[path];
  }
  return [];
}
