'use client';
import { clsx } from 'clsx';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  type JSX,
} from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { ChevronDown } from 'geist/icons';
import { Drawer } from 'geist/components';
import { Link } from '@pyra/multi-zone/link';
import {
  AnalyticsEvent,
  analytics,
} from '@vercel/site-analytics/vercel-client';
import { getSecondaryNavigation } from '@pyra/docs-shared/data/navigation/utils';
import { primaryNavigation } from '@pyra/docs-shared/data/navigation/primary';
import type {
  ToC as ToCItem,
  ToCData,
} from '@pyra/docs-shared/data/navigation/types';
import { frameworks } from '#/app/components/switchers/data';
import { extractHeadings } from '#/app/components/layout/navbar/utils';
import { FrameworkSelect } from '#/app/components/layout/navbar/mobile-bottom-menu/framework-select';
import { FrameworkTrigger } from '#/app/components/layout/navbar/mobile-bottom-menu/framework-trigger';
import { BackToTop } from '#/app/components/layout/navbar/mobile-bottom-menu/back-to-top';
import { RelatedSideCard } from '#/app/components/related-side-card';
import styles from './toc.module.css';

const ITEMCHARLIMIT = 30;

interface ToCProps {
  /**
   * The anchor headings on the page
   */
  headings: ToCData | undefined;
}

interface OrderedHeading extends ToCItem {
  order: number;
}

/**
 * Get the paths to the headings
 */
function getIds({ headings }: ToCProps): string[] | undefined {
  return headings?.reduce((acc: string[], item: ToCItem) => {
    acc.push(item.href.slice(1));
    return acc;
  }, []);
}

/**
 * Get the headings that are in the current viewport
 */
function useActiveId(itemIds: string[] | undefined): string {
  const [activeId, setActiveId] = useState(itemIds ? itemIds[0] : '');
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.length === 0) return;
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
          }
        });
      },
      { rootMargin: `0% 0% -80% 0%` },
    );
    itemIds?.forEach((id) => {
      if (id && document.getElementById(id)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Style Guide V4 Upgrade
        observer.observe(document.getElementById(id)!);
      }
    });
  }, [itemIds]);
  return activeId;
}

function truncateString(string: string, limit: number): string {
  if (string.length > limit) {
    return `${string.substring(0, limit)}...`;
  }
  return string;
}

export function ToC({ children }: { children: React.ReactNode }): JSX.Element {
  const headings: ToCData = extractHeadings(children);
  const ToCRef = useRef<HTMLElement>(null);
  const idList = getIds({ headings });
  const activeId = useActiveId(idList);
  const toManyItems = headings.length > 10;
  const path = usePathname() as string;
  const secondaryNavigation = getSecondaryNavigation(primaryNavigation, path);
  const hasSecondaryNav = Boolean(secondaryNavigation.navItems.length > 0);
  const [showFrameworkSelector, setShowFrameworkSelector] = useState(false);
  // we will apply some extra margin to account for extra lines on this pages headings
  const isFAQ = path.includes('/frequently-asked-questions');

  const wordCount = (words: string): number => words.split(' ').length;

  // h/t @lfades for this code
  const findClosestParent = useCallback(
    (
      items: OrderedHeading[],
      heading: ToCItem,
      i: number,
    ): OrderedHeading | undefined => {
      const parent = items[i - 1] as OrderedHeading | undefined;

      if (!parent) return;
      if (parent.level < heading.level) return parent;

      return findClosestParent(items, heading, i - 1);
    },
    [],
  );

  const orderedHeadings = useMemo(() => {
    return headings.reduce<OrderedHeading[]>((acc, heading, i) => {
      const parent = findClosestParent(acc, heading, i);
      acc.push({ ...heading, order: parent ? parent.order + 1 : 1 });
      return acc;
    }, []);
  }, [findClosestParent, headings]);

  const supportedFrameworks = frameworks.filter((item) =>
    item.supportedFeatures?.some((feature) => path.includes(feature)),
  );

  const handleFrameworkSelect = (): void => {
    setShowFrameworkSelector(!showFrameworkSelector);
  };

  return (
    <aside
      className={clsx(
        styles.toc,
        toManyItems ? styles.tocScrollbar : '',
        hasSecondaryNav && styles.hasSecondaryNav,
      )}
      ref={ToCRef}
    >
      {supportedFrameworks.length > 0 && (
        <div className={styles.desktopFramework}>
          <div
            className={clsx(
              styles.DropDownContainer,
              styles.desktopDDContainer,
            )}
            onClick={(): void => handleFrameworkSelect()}
            onKeyDown={(): void => handleFrameworkSelect()}
            role="button"
            tabIndex={0}
          >
            <FrameworkTrigger selectorOpened={showFrameworkSelector} />
          </div>
          <div
            className={
              showFrameworkSelector
                ? clsx(
                    styles.popoverContainer,
                    styles.showPopover,
                    styles.tabletView,
                  )
                : styles.popoverContainer
            }
          >
            <FrameworkSelect
              handleIndexClick={(): void => handleFrameworkSelect()}
              pathname={path}
            />
          </div>
        </div>
      )}
      {orderedHeadings.length ? (
        <div className={styles.tocHeader}>
          <span>On this page</span>
        </div>
      ) : null}
      <ul>
        {orderedHeadings.length
          ? orderedHeadings.map(({ name, href, order }: OrderedHeading) => (
              <li
                className={clsx(
                  styles.tocItem,
                  isFAQ ? styles.faq : '',
                  styles[`level-${order}`],
                  activeId === href.slice(1) ? styles.active : '',
                )}
                id={`toc_${href.slice(1)}`}
                key={name}
              >
                <Link
                  href={href.replaceAll('`', '')}
                  onClick={(): void => {
                    analytics.track(AnalyticsEvent.DOC_TOC_CLICKED, {
                      page: path,
                      tocLink: name.replace(/\b\d{3}:/, ''),
                    });
                  }}
                >
                  {wordCount(name) > 10
                    ? truncateString(name, ITEMCHARLIMIT).replace(
                        /\b\d{3}:/,
                        '',
                      )
                    : name.replace(/\b\d{3}:/, '')}
                </Link>
              </li>
            ))
          : null}
      </ul>
      <RelatedSideCard hasToC={orderedHeadings.length > 0} />
      {headings.length ? <BackToTop showText /> : null}
    </aside>
  );
}

export function MobileToC({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const headings: ToCData = extractHeadings(children);
  const idList = getIds({ headings });
  const activeId = useActiveId(idList);
  const [showMenuSelector, setShowMenuSelector] = useState(false);
  const [showFrameworkSelector, setShowFrameworkSelector] = useState(false);
  const [tocId, setTocId] = useState('');
  const path = usePathname() as string;
  const router = useRouter();
  // we will apply some extra margin to account for extra lines on this pages headings
  const isFAQ = path.includes('/frequently-asked-questions');

  const wordCount = (words: string): number => words.split(' ').length;

  // h/t @lfades for this code
  const findClosestParent = useCallback(
    (
      items: OrderedHeading[],
      heading: ToCItem,
      i: number,
    ): OrderedHeading | undefined => {
      const parent = items[i - 1] as OrderedHeading | undefined;

      if (!parent) return;
      if (parent.level < heading.level) return parent;

      return findClosestParent(items, heading, i - 1);
    },
    [],
  );

  const orderedHeadings = useMemo(() => {
    return headings.reduce<OrderedHeading[]>((acc, heading, i) => {
      const parent = findClosestParent(acc, heading, i);
      acc.push({ ...heading, order: parent ? parent.order + 1 : 1 });
      return acc;
    }, []);
  }, [findClosestParent, headings]);

  const handleMenuSelect = (): void => {
    if (showFrameworkSelector) {
      setShowFrameworkSelector(false);
    }
    setShowMenuSelector(!showMenuSelector);
  };

  const handleFrameworkSelect = (): void => {
    if (showMenuSelector) {
      setShowMenuSelector(false);
    }
    setShowFrameworkSelector(!showFrameworkSelector);
  };

  const supportedFrameworks = frameworks.filter((item) =>
    item.supportedFeatures?.some((feature) => path.includes(feature)),
  );

  function scrollToId(href: string): void {
    setTocId(href);
    hideMenus();
  }

  function hideMenus(): void {
    if (showMenuSelector) {
      setShowMenuSelector(false);
    }
    if (showFrameworkSelector) {
      setShowFrameworkSelector(false);
    }
  }

  return (
    <>
      <div className={styles.MobileMainContainer}>
        <div className={styles.DropDownContainer}>
          <div className={clsx(styles.selectContainer, styles.navContainer)}>
            <div
              className={styles.TOCContainer}
              onClick={(): void => handleMenuSelect()}
              onKeyDown={(): void => handleMenuSelect()}
              role="button"
              tabIndex={0}
            >
              <span className={styles.selectedItem}>Table of Contents</span>
              <span
                className={
                  showMenuSelector
                    ? clsx(styles.arrow, styles.arrowUp)
                    : styles.arrow
                }
              >
                <ChevronDown size={16} />
              </span>
            </div>
            {supportedFrameworks.length > 0 && (
              <div
                className={styles.tabletFrameworkContainer}
                onClick={(): void => handleFrameworkSelect()}
                onKeyDown={(): void => handleFrameworkSelect()}
                role="button"
                tabIndex={0}
              >
                <FrameworkTrigger selectorOpened={showFrameworkSelector} />
              </div>
            )}
          </div>
        </div>
      </div>
      <Drawer
        onDismiss={(): void => {
          router.push(tocId);
          hideMenus();
        }}
        show={showMenuSelector}
      >
        <div className="px-[16px] py-[12px]">
          {orderedHeadings.length
            ? orderedHeadings.map(({ name, href, order }: OrderedHeading) => (
                <div
                  className={clsx(
                    styles.tocItemMobile,
                    isFAQ ? styles.faq : '',
                    styles[`level-${order}`],
                    activeId === href.slice(1) ? styles.active : '',
                  )}
                  key={name}
                >
                  <button
                    onClick={(): void => scrollToId(href.replaceAll('`', ''))}
                    type="button"
                  >
                    {wordCount(name) > 10
                      ? truncateString(name, ITEMCHARLIMIT).replace(
                          /\b\d{3}:/,
                          '',
                        )
                      : name.replace(/\b\d{3}:/, '')}
                  </button>
                </div>
              ))
            : null}
        </div>
      </Drawer>
      <Drawer
        onDismiss={(): void => setShowFrameworkSelector(false)}
        show={showFrameworkSelector}
      >
        <div className="px-[16px] py-[12px]">
          <FrameworkSelect
            handleIndexClick={(): void => handleFrameworkSelect()}
            pathname={path}
          />
        </div>
      </Drawer>
    </>
  );
}
