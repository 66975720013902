//Object of paths that have related content items as an array of paths that exist in the documentation navigation object
export const relatedContentItems = {
  '/docs/functions/functions-api-reference': [
    {
      title: 'Streaming Data',
      description: 'Learn about streaming on Vercel',
      href: '/docs/functions/streaming',
    },
  ],
  '/docs/functions/streaming': [
    {
      title: 'Streaming and SEO',
      description: 'Does streaming affect SEO?',
      href: '/guides/does-streaming-affect-seo',
      type: 'external',
    },
    {
      title: 'Streaming from LLMs',
      description: "Learn how to stream LLMs with Vercel's AI SDK.",
      href: '/docs/recipes/streaming-from-llm',
      type: 'recipe',
    },
    {
      title: 'Processing data chunks',
      description: 'Learn how to process data chunks.',
      href: '/docs/recipes/processing-data-chunks',
      type: 'recipe',
    },
    {
      title: 'Handling backpressure',
      description: 'Learn how to handle backpressure.',
      href: '/docs/recipes/handling-backpressure',
      type: 'recipe',
    },
  ],
  '/docs/speed-insights': [
    {
      title: 'How Core Web Vitals affect SEO',
      description:
        "Learn how to understand your application's Google page experience ranking and Lighthouse scores.",
      href: 'https://www.youtube.com/watch?v=qIyEwOEKnE0',
      type: 'video',
    },
  ],
  '/docs/workflow-collaboration/conformance': [
    {
      title: 'Conformance and Code Owners',
      description:
        'Learn about how Vercel helps organizations grow with Conformance and Code owners.',
      href: 'https://www.youtube.com/watch?v=IFkZz3_7Poo',
      type: 'video',
    },
  ],
  '/docs/accounts/spend-management': [
    {
      title: 'Spend limits for Vercel',
      description:
        'Learn how to view, monitor, and alert on your Vercel usage.',
      href: 'https://youtu.be/-_vpoayWTps?si=Jv6b8szx68lVHGYz',
      type: 'video',
    },
  ],
  '/docs/integrations/ai': [
    {
      title: 'AI Integrations for Vercel',
      description: 'Learn how Vercel integrates with popular AI companies.',
      href: 'https://www.youtube.com/watch?v=so4Jatc85Aw',
      type: 'video',
    },
  ],
  '/docs/rest-api/endpoints/user': [
    {
      title: 'Event types',
      description:
        'Possible event types that can be used for types query param in the List User Events endpoint.',
      href: '/docs/observability/activity-log#events-logged',
      type: 'docs',
    },
  ],
  '/docs/deployments/environments': [
    {
      title: 'Environments on Vercel',
      description: 'Learn about the different environments on Vercel.',
      href: 'https://www.youtube.com/watch?v=nZrAgov_-D8',
      type: 'video',
    },
  ],
  '/docs/frameworks/nextjs': [
    {
      title: 'Deploying Next.js to Vercel',
      description:
        'Deploy to Vercel by with a template, the Vercel CLI, or pushing commits to your Git repository.',
      href: 'https://www.youtube.com/watch?v=AiiGjB2AxqA',
      type: 'video',
    },
    {
      title: 'Partial Prerendering',
      description:
        'Learn about combining static and dynamic rendering on the same page in Next.js 14.',
      href: 'https://www.youtube.com/watch?v=wv7w_Zx-FMU',
      type: 'video',
    },
    {
      title: 'Loading UI and Streaming',
      description:
        'Learn about suspense boundaries and streaming when loading your UI.',
      href: 'https://nextjs.org/docs/app/building-your-application/routing/loading-ui-and-streaming',
      type: 'external',
    },
  ],
  '/docs/projects/domains/working-with-domains': [
    {
      title: 'What is a Domain Name?',
      description:
        'Learn what domain names are, how they work, and why they are important.',
      href: 'https://developer.mozilla.org/en-US/docs/Learn/Common_questions/Web_mechanics/What_is_a_domain_name',
    },
  ],
  '/docs/integrations/marketplace-product': [
    {
      title: 'Native integrations API reference',
      description:
        'Learn how to use the Rest API endpoints to set up your native integration server for the Base URL',
      href: '/docs/integrations/marketplace-api',
    },
    {
      title: 'Native integration server',
      description:
        'Use this code as a sample for the integration server to be used as the Base URL in the product specification.',
      href: 'https://github.com/vercel/example-marketplace-integration',
    },
    // {
    //   title: 'Integration Flow Diagrams',
    //   description:
    //     'Learn how information flows between the user, Vercel and the partner.',
    //   href: '/docs/integrations/marketplace-flows',
    // },
  ],
  '/docs/pricing': [
    {
      title: 'Improved infrastructure pricing',
      description: 'Learn about the improved infrastructure pricing on Vercel.',
      href: '/blog/improved-infrastructure-pricing',
    },
  ],
  '/docs/pricing/understanding-usage-and-accrual': [
    {
      title: 'How to optimize resource consumption',
      description: 'Learn how to optimize resource consumption on Vercel.',
      href: '/docs/pricing/understanding-usage-and-accrual/how-can-i-optimize-resource-consumption',
    },
    {
      title: 'Learn about regional pricing',
      description: 'Understand how regional pricing works on Vercel.',
      href: '/docs/pricing#regional-pricing',
    },
  ],
  '/docs/pricing/data-cache': [
    {
      title: 'Usage of Data Cache with ISR',
      description:
        'Learn how Next.js combines Vercel Data Cache with Incremental Static Regeneration (ISR).',
      href: '/docs/infrastructure/data-cache#comparing-with-isr-and-edge-cache',
    },
    {
      title: 'Data Cache Revalidation',
      description: 'Review time-based and tag-based revalidation examples.',
      href: '/docs/infrastructure/data-cache#examples',
    },
    {
      title: 'How Data Cache works',
      description: 'Learn how Data Cache works in Next.js.',
      href: 'https://nextjs.org/docs/app/building-your-application/caching#data-cache',
    },
  ],
  '/docs/pricing/edge-config': [
    {
      title: 'Troubleshooting',
      description:
        'Learn how to identify common problems like slow reads and specific errors.',
      href: '/docs/storage/edge-config/edge-config-limits#troubleshooting',
    },
  ],
  '/docs/pricing/artifacts': [
    {
      title: 'Remote Caching',
      description: 'Learn how to work with Remote Cache Artifacts.',
      href: '/docs/monorepos/remote-caching#get-started',
    },
    {
      title: 'Remote Cache SDK',
      description: 'Use this SDK to manage Remote Cache Artifacts.',
      href: 'https://github.com/vercel/remote-cache',
    },
  ],
  '/docs/edge-network/headers': [
    {
      title: 'Set Caching Header',
      description: 'A recipe on setting caching headers',
      href: '/docs/recipes/set-cache-control-headers',
      type: 'recipe',
    },
  ],
  '/docs/edge-network/caching': [
    {
      title: 'Set Caching Header ',
      description: 'A recipe on setting caching headers',
      href: '/docs/recipes/set-cache-control-headers',
      type: 'recipe',
    },
  ],
  '/docs/speed-insights/package': [
    {
      title: 'Sending a sample of your events',
      description:
        'Example of sending a sample of your events to Speed Insights',
      href: '/docs/recipes/sending-sample-to-speed-insights',
      type: 'recipe',
    },
  ],
  '/docs/functions/og-image-generation': [
    {
      title: 'OG image recipes',
      description: 'Learn how to pass different parameters with code examples',
      href: '/docs/recipes#og-image-generation',
    },
  ],
  '/docs/functions/edge-middleware': [
    {
      title: 'Add a response header',
      description: 'Learn how to add a response header to your middleware',
      href: '/docs/recipes/add-response-header',
    },
    {
      title: 'Modifying request headers',
      description: 'Learn how to modify request headers in your middleware',
      href: '/docs/recipes/modify-request-headers',
    },
  ],
  '/docs/functions/edge-middleware/middleware-api': [
    {
      title: 'Redirect with unique tokens',
      description:
        'Learn how to redirect requests with a unique token using the cypto Web API',
      href: '/docs/recipes/use-crypto-web-api',
    },
  ],
  '/docs/security/vercel-waf/ip-blocking': [
    {
      title: 'Geolocation region block',
      description:
        'Learn how to block traffic from a specific geographic region using a custom rule.',
      href: '/docs/security/vercel-waf/examples#suspicious-traffic-in-specific-regions',
    },
  ],
};
