'use client';
import { usePathname } from 'next/navigation';
import { Code, Document, ExternalLink, Video, Github } from 'geist/icons';
import { CodeWrap, External } from 'geist/new-icons/16';
import type { JSX } from 'react';
import { Link } from '@pyra/multi-zone/link';
import { relatedPageItems } from '@pyra/docs-shared/data/related-content/utils';
import type { ContentType } from '@pyra/docs-shared/data/related-content/types';
import React from 'react';

export function RelatedSideCard({ hasToC }: { hasToC: boolean }): JSX.Element {
  const pathname = usePathname();
  const relatedItems = relatedPageItems(pathname || '');
  return (
    <>
      {relatedItems.length > 0 ? (
        <div className="mb-[12px] mr-[12px] mt-[12px] flex flex-col items-start gap-[12px] self-stretch rounded border border-solid border-[var(--accents-2)] p-[16px]">
          <div className="text-[11px] font-bold uppercase tracking-[0.44px] text-[color:var(--accents-5)]">
            Related
          </div>
          {relatedItems.map((item) => {
            const externalURL = item.href.includes('https');
            let contentType: ContentType = 'doc';
            if (item.type) {
              contentType = item.type;
            } else if (item.href.includes('https://www.youtube.com/')) {
              contentType = 'video';
            } else if (item.href.includes('https://www.github.com/')) {
              contentType = 'github';
            } else if (externalURL) {
              contentType = 'external';
            }
            return (
              <div key={item.title}>
                <Link
                  href={item.href}
                  target={externalURL ? '_blank' : '_self'}
                  variant="highlight"
                >
                  <div className="flex items-center gap-[6px] text-[12px] leading-[20px] tracking-[0.44px] underline hover:no-underline">
                    <ContentIcon type={contentType} />
                    {item.title}
                  </div>
                  <div className="text-[12px] font-normal leading-[18px] text-[color:var(--accents-5)]">
                    {item.description}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          {hasToC ? (
            <div className="mx-0 my-[8px] h-[1px] w-full border-0 border-b border-solid border-[var(--accents-2)]" />
          ) : null}
        </>
      )}
    </>
  );
}

function ContentIcon({ type }: { type: ContentType }): JSX.Element {
  if (type === 'doc') {
    return <Document size={16} />;
  }
  if (type === 'recipe') {
    return <Code size={16} />;
  }
  if (type === 'external') {
    return <ExternalLink size={16} />;
  }
  if (type === 'video') {
    return <Video size={16} />;
  }
  if (type === 'github') {
    return <Github size={20} />;
  }
  if (type === 'reference') {
    return <CodeWrap size={16} />;
  }
  if (type === 'deploy') {
    return <External size={16} />;
  }
  return <Document size={16} />;
}
