'use client';
import { usePathname } from 'next/navigation';
import type { JSX } from 'react';

export function DocsMetadata({
  meta: { title, description },
}: {
  meta: {
    title: string;
    description: string;
  };
}): JSX.Element | null {
  const path = usePathname() as string;
  return (
    <>
      <title>{title}</title>
      <meta content={description} name="description" />
      <link href={`https://vercel.com${path}`} rel="canonical" />
      <meta content={`https://vercel.com${path}`} property="og:url" />
      <meta content={title} property="og:title" />
      <meta content={description} property="og:description" />
      <meta
        content={`${
          process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
            ? 'https://vercel.com'
            : `https://${process.env.NEXT_PUBLIC_VERCEL_URL || ''}`
        }/api/dynamic-og?title=${encodeURIComponent(title)}`}
        property="og:image:url"
      />
    </>
  );
}
